.loader {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #3b0062;
  border-radius: 50%;
  animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #3b0062 transparent transparent transparent;
  border-color: #999 transparent transparent transparent;
}
.loader div:nth-child(1) {
  animation-delay: -0.45s;
}
.loader div:nth-child(2) {
  animation-delay: -0.3s;
}
.loader div:nth-child(3) {
  animation-delay: -0.15s;
}

.loaderSmall {
  display: inline-block;
  position: relative;
  /* width: 32rem;
  height: 32rem; */
  width: 1.5rem;
  height: 1.5rem;
}

.loaderSmall div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 3px;
  border: 3px solid #3b0062;
  border-radius: 50%;
  animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #3b0062 transparent transparent transparent;
  border-color: #999 transparent transparent transparent;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
