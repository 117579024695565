.topBarContentsContainer {
  display: flex;
  /* align-items: center; */
}

.topBarContentsLeft {
  display: flex; 
  flex-direction: column;
  width: 235px;
  margin-left:0.25rem;
  margin-top: 0.3rem;
}

.topBarContentsImage {
  width: 65px;
}

.groupTalkRightElementLink {
  text-decoration: none;
  color: var(--color);
}