.rightContentsImageContainer {
  text-align: center;
}

.rightContentsTitle {
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.25rem;
}

.rightContentsText {
  font-weight: bolder;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
}

.rightContentsImage {
  margin: 0.25rem 0.1rem;
}

.groupTalkRightElementLink {
  text-decoration: none;
  color: var(--color);
}

.groupTalkRightElementLinkContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}